import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { Hours } from 'wsm-hours';
import ResultsHeading from './ResultsHeading';

const DepartmentHoursTabs = ({
	departments,
	departmentHours,
	label,
	labels,
	labelSize,
	ariaLabelledById,
	handleLinkClick,
	tabId
}) => {
	const handleClick = (eventKey) => {
		handleLinkClick('richContentLink', `${eventKey[0]}${eventKey.substr(1).toLowerCase()}`, '', 'navigated department hours tabs' );
	};
	let validDepartments = departmentHours;
	if (validDepartments.DEALERSHIP && !validDepartments.SALES) {
		validDepartments.SALES = validDepartments.DEALERSHIP;
	}
	validDepartments = departments.filter(
		(deptName) => !!departmentHours[deptName.toUpperCase()]
	);

	const optionalAttributes = {
		...(label && { 'aria-labelledby': ariaLabelledById })
	};

	return validDepartments && validDepartments.length ? (
		<div
			className="department-hours-tabs mb-4 tabs tabs-fill ws-hours"
			key="department-tabs"
			{...optionalAttributes}
		>
			{label && (
				<ResultsHeading
					label={label}
					labelSize={labelSize}
					ariaLabelledById={ariaLabelledById}
				/>
			)}
			<Tabs onSelect={handleClick} className="mx-4" id={tabId}>
				{validDepartments.map((department) => {
					const departmentCaps = department.toUpperCase();
					return (
						<Tab
							className="py-4"
							title={labels.get(departmentCaps)}
							eventKey={departmentCaps}
							key={departmentCaps}
						>
							<Hours
								hours={{
									// don't need to pass the whole hours object
									// only pass the hours for the dept we are displaying
									[departmentCaps]:
										departmentHours[departmentCaps]
								}}
								isModal={false}
								showCurrentDayOnly={false}
								department={departmentCaps}
								dayColClasses="col-xs-6 col-md-5"
								hourColClasses="col-xs-6 col-md-7"
							/>
						</Tab>
					);
				})}
			</Tabs>
		</div>
	) : null;
};

const DEPARTMENT_HOURS_PROP_TYPE = PropTypes.arrayOf(
	PropTypes.shape({
		day: PropTypes.string,
		timings: PropTypes.string
	})
);

DepartmentHoursTabs.propTypes = {
	departments: PropTypes.arrayOf(PropTypes.string).isRequired,
	departmentHours: PropTypes.shape({
		SALES: DEPARTMENT_HOURS_PROP_TYPE,
		SERVICE: DEPARTMENT_HOURS_PROP_TYPE,
		PARTS: DEPARTMENT_HOURS_PROP_TYPE,
		COMMERCIAL: DEPARTMENT_HOURS_PROP_TYPE
	}).isRequired,
	label: PropTypes.string,
	labels: PropTypes.shape({ get: PropTypes.func.isRequired }).isRequired,
	labelSize: PropTypes.number,
	ariaLabelledById: PropTypes.string,
	handleLinkClick: PropTypes.func,
	tabId: PropTypes.string
};
DepartmentHoursTabs.defaultProps = {
	label: '',
	labelSize: 5,
	ariaLabelledById: 'department-hours-0',
	handleLinkClick() {},
	tabId: 'department-hours-1'
};

export default DepartmentHoursTabs;
