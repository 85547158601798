import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import Address from 'wsm-contact-address';
import ResultsHeading from './ResultsHeading';

const AddressCard = ({
	name,
	address1,
	city,
	state,
	postalCode,
	getDirectionsLabel,
	getDirectionsUrl,
	addressClasses,
	label,
	labelSize,
	ariaLabelledById,
	handleLinkClick
}) => {
	const optionalAttributes = {
		...(label && { 'aria-labelledby': ariaLabelledById })
	};
	const handleClick = () => {
		handleLinkClick('richContentLink', getDirectionsLabel, '', '' );
	};
	const addressElementClasses = [
		...addressClasses.split(' '),
		'text-black',
		'my-3'
	];

	return (
		<React.Fragment>
			{label && (
				<ResultsHeading
					label={label}
					labelSize={labelSize}
					ariaLabelledById={ariaLabelledById}
				/>
			)}
			<div
				{...optionalAttributes}
				className="bg-location bg-neutral-0 d-flex border p-4 mx-4 mb-4"
			>
				<div className="d-sm-inline mx-4">
					<i
						className="ddc-icon ddc-icon-map ddc-font-size-xlarge text-black"
						aria-hidden="true"
					/>
				</div>
				<Address
					name={name}
					address1={address1}
					city={city}
					state={state}
					postalCode={postalCode}
					url={{
						text: getDirectionsLabel,
						link: getDirectionsUrl
					}}
					addressClasses={setClassNames(addressElementClasses) || ''}
					onGetDirectionsClick={handleClick}
				/>
			</div>
		</React.Fragment>
	);
};
AddressCard.propTypes = {
	name: PropTypes.string.isRequired,
	address1: PropTypes.string.isRequired,
	city: PropTypes.string.isRequired,
	state: PropTypes.string.isRequired,
	postalCode: PropTypes.string.isRequired,
	getDirectionsLabel: PropTypes.string.isRequired,
	getDirectionsUrl: PropTypes.string.isRequired,
	addressClasses: PropTypes.string,
	label: PropTypes.string,
	labelSize: PropTypes.number,
	ariaLabelledById: PropTypes.string,
	handleLinkClick: PropTypes.func
};
AddressCard.defaultProps = {
	label: '',
	labelSize: 5,
	ariaLabelledById: 'address-card-0',
	addressClasses: '',
	handleLinkClick() {}
};
export default AddressCard;
